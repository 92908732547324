//import $ from 'jquery'
import { gsap, CSSPlugin, ScrollToPlugin } from 'gsap/all'
import { Power4 } from 'gsap'
gsap.registerPlugin(ScrollToPlugin);
let global = {
    init: function () {
        $('.jump').on('click',global.jump)

        $('.download-all-action-link').on('click', global.downloadAll)
    },
    ////////////////////////////////////////////////////////////////////////////////////

    jump: function() {
        let tid = '#' + $(this).attr('data-jump')
        gsap.to(window, {duration: 0.75, ease: Power4.easeInOut, scrollTo: {y: tid, offsetY: 70}});
    },

    downloadAll: function (e) {
        e.preventDefault()
        let form = $(this).closest('form')
        form.off('submit')
        form.on('submit', global.downloadAllSubmit)
        $(this).closest('form').submit()
    },
    downloadAllSubmit: function(e) {
        e.preventDefault(); // Prevent default form submission
        let formData = new FormData(this); // Gather form data
        $('.download-all-action-link').hide()
        let txt = global.generateLoadingText('Preparing file')
        $('.download-all-action-link-msg').html(txt)
        fetch('/actions/download-all', {
            method: 'POST',
            body: formData,
            redirect: 'follow'
        })
            .then(response => {
                console.log('response', response);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                console.log('blob', blob);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = site_segment1 + '-images.zip';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
                $('.download-all-action-link').show();
                $('.download-all-action-link-msg').html('Success!');
            })
            .catch(error => {
                $('.download-all-action-link').show();
                $('.download-all-action-link-msg').html('Error: Please try later');
                console.error('Error:', error);
            });


    },

    generateLoadingText(label = 'Loading showtimes') {
        return `<div class="loading-text">
                    <div class="label">${label}</div>
                    <div class="ellipse-loading">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>`
    },


}
export default global
