//DEFAULTS
import "core-js/stable";
import "regenerator-runtime/runtime";

import site from 'site/site'
import global from 'site/global'
import siteScroll from 'site/scroll'
import mobileNav from 'site/mobileNav'
import siteAOS from 'site/site.aos'
//import oneId from 'site/oneId'
import overlay from 'site/overlay'
import mediaOverlay from 'site/mediaOverlay'

$(document).ready(function() {
    site.init()
    global.init()

    mobileNav.init()
    siteScroll.init()
    overlay.init()
    mediaOverlay.init()

    // LAST
    // oneId.init()
    setTimeout( function() {
        siteAOS.init()
        siteAOS.refreshAOS()
    },500)

})
