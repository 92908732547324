import siteCommon from 'site/site-common'
import gsap, { Power3 } from 'gsap'
let mobileNav = {
    init:function () {
        var self = this;
        $('#mobile-nav-hamburger .hamburger').click(self.doHamburerToggle);
        $('#mobile-nav-overlay .navitem').click(self.overlayHide);
        $('#mobile-nav-overlay .mobile-nav-expander').click(self.subNavToggle);
        $(document).keyup(function(e) {
            if (e.keyCode === 27) { // escape key
                mobileNav.overlayHide();
            }
        })
        mobileNav.overlayHide();

        $('.ellipsis-outer').click(self.ellipsisClick);

        $(window).on( "resize", mobileNav.resize);
    },//init

    resize () {
        const h1Outer = $('#film-nav-overlay .mobile-content1').height()
        const h2Outer = $('#film-nav-overlay .mobile-content2').height()

        // better centering of content in scroll or no scroll situation
        if (h2Outer > h1Outer) {
            $('#film-nav-overlay .mobile-content1').addClass('block')
        } else {
            $('#film-nav-overlay .mobile-content1').removeClass('block')
        }
    },

    getLastSegment: function (inputString) {
        const segments = inputString.split('.');
        return segments[segments.length - 1];
    },

    extractDomain(url) {
        let domain = ''
        if (url) {
            // Remove the protocol (http/https)
            let withoutProtocol = url.replace(/^https?:\/\//, '')

            // Remove 'www' if present
            let withoutWww = withoutProtocol.replace(/^www\./, '')

            // Remove everything after the first "/"
            let domainWithTld = withoutWww.split('/')[0]

            // Remove the top-level domain
            domain = domainWithTld.split('.').slice(0, -1).join('.')

            // extract the last segment
            domain = mobileNav.getLastSegment(domain)
        }

        return domain
    },


    ellipsisClick: function() {
        let metaH1 = $('.ellipsis-meta h2', this).html()
        let metaBugs = $('.ellipsis-meta .bugs', this).html()
        let metaLink = $('.ellipsis-meta', this).attr('data-link')
        //if ($('#mobile-nav-hamburger .hamburger').hasClass('is-active')) {
        //    mobileNav.overlayHide();
        //} else {
            // elipsis setup
            mobileNav.modeToAdd('mode-film')

            $('#ellipsis-hook-h1').html(metaH1)
            $('#ellipsis-hook-bugs').html(metaBugs)
            $('#ellipsis-link').attr('href',metaLink)

            mobileNav.overlayShow()
        //}
    },

    doHamburerToggle:function(){

        if ($(this).hasClass('is-active')) {
            mobileNav.overlayHide();
        } else {
            mobileNav.modeToAdd('mode-nav')
            mobileNav.overlayShow();
        }
    },//doHamburerToggle

    subNavToggle:function() {
        if ($(this).hasClass('open')) {
            mobileNav.subNavClose();
        } else {
            mobileNav.subNavOpen(this);
        }
    },
    subNavOpen:function(t) {
        mobileNav.subNavClose();
        $(t).addClass('open');
    },
    subNavClose:function() {
        $('#mobile-nav-overlay .header-2-nav-item-a').removeClass('open');
    },
    overlayShow:function() {
        const mode = siteCommon.scGetSiteMode()
        if (mode === 'tiny') {
            $('body').addClass('no-scroll')
        }

        //siteCommon.scOpenTopOfPage()
        $('#mobile-nav-hamburger .hamburger').addClass('is-active');
        $('#mobile-nav-overlay').removeClass('hidden');
        $('#mobile-nav-overlay-outer').removeClass('hidden');

        let tl = gsap.timeline({})
        tl.fromTo("#mobile-nav-overlay-outer", 1.4,
            {height:0},
            {height:'auto', ease:Power3.easeOut},
        );
        tl.staggerFromTo("#mobile-nav-overlay .mobile-reveal", 0.5,
            {opacity:0, y:-10 },
            {opacity:1, y:0, ease:Power3.easeOut},
            0.03,//stagger,
            "-=1.2"
        );

        mobileNav.resize()
    },
    overlayHide:function() {
        $('#mobile-nav-hamburger .hamburger').removeClass('is-active');
        $('#mobile-nav-overlay').addClass('hidden');
        $('#mobile-nav-overlay-outer').addClass('hidden');
        mobileNav.modeToAdd('')

        mobileNav.resize()
        $('body').removeClass('no-scroll')

    },

    modeToAdd (mode='') {
        $('#mobile-nav-overlay-outer').removeClass('mode-nav')
        $('#mobile-nav-overlay-outer').removeClass('mode-buy')
        $('#mobile-nav-overlay-outer').removeClass('mode-film')
        $('#mobile-nav-hamburger').removeClass('mode-nav')
        $('#mobile-nav-hamburger').removeClass('mode-buy')
        $('#mobile-nav-hamburger').removeClass('mode-film')
        $('body').removeClass('mode-nav')
        $('body').removeClass('mode-buy')
        $('body').removeClass('mode-film')

        if (mode !== '') {
            $('#mobile-nav-overlay-outer').addClass(mode)
            $('#mobile-nav-hamburger').addClass(mode)
            $('body').addClass(mode)
        }

        // analytics
        if (mode === 'mode-buy') {
            showtimesAnalytics(site_segment1)
        }
    }

}; //obj
export default mobileNav
