import gsap, { Power4 } from 'gsap'
import siteCommon from 'site/site-common'
let overlay = {
    overlayType:'',
    overlayIsOpen:false,
    youtubeId:'',
    yt: null,
    ytInterval: null,
    ytOverlayIsReady: false,
    init:function () {
        $(window).on( "resize", overlay.resize);
        $('.overlay-hook').on('click', overlay.doOverlayHandle);
        $('#overlay .overlay-x').on('click', overlay.doOverlayClose);
        $(document).keyup(function(e) {
            if (overlay.overlayIsOpen) {
                if (e.keyCode === 27) { // escape key
                    overlay.doOverlayClose();
                }
            }
        })
        if (globalCookiesDisabledOptOut === false) {
            overlay.init2()
        }
    }, //init
    init2 () {
        if (!youTubeIsReady) {
            overlay.ytInterval = setTimeout(overlay.init2, 500)
        } else {
            overlay.init3()
        }
    },
    init3 () {
        clearTimeout(overlay.ytInterval)
        overlay.yt = new YT.Player('ytplayer', {
            height: '390',
            width: '640',
            // videoId: overlay.youtubeId,
            host: 'https://www.youtube-nocookie.com',
            playerVars: {
                origin: window.location.host,
                'autoplay': 1,
                'controls': 1,
                'playsinline': 1,
                'modestbranding': 1,
                'rel': 0
            },
            events: {
                'onReady': overlay.onPlayerReady,
                'onStateChange': overlay.onPlayerStateChange
            }
        });
    },

    doOverlayHandle:function() {
        let overlayType = $(this).attr('data-overlay')
        let youtubeId = $(this).attr('data-youtubeId')
        overlay.doOverlayHandleSetValues(overlayType, youtubeId)
    },

    doOverlayHandleSetValues:function(overlayType, youtubeId) {
        overlay.overlayIsOpen = false
        overlay.youtubeId = youtubeId
        overlay.overlayType = overlayType
        overlay.doOverlayOpen("overlay-" + overlayType)
    },

    doOverlayOpen:function(overlayTemplate) {
        let openit = false
        if (overlay.overlayIsOpen===false) {
            openit = (overlay.ytOverlayIsReady || overlay.overlayType === 'epk')
        }
        if (openit) {
            //hide all, show
            $('.overlay-section').hide()
            $('#'+overlayTemplate).show()

            //set fade in
            $('#overlay').css('opacity',0)
            $('#overlay').show()

            $(".overlay-x .close-button").removeClass('active')

            //special
            $('#overlay-scrim').removeClass('epk')
            if (overlayTemplate==="overlay-trailer-api") {
                overlay.doOverlayOpenReveal();
                if (globalCookiesDisabledOptOut === false) {
                    overlay.yt.loadVideoById(overlay.youtubeId)
                } else {
                    $('#ytplayer').html(`<span>Watch this video on&nbsp;<a href="https://www.youtube.com/watch?v=${overlay.youtubeId}" target="_blank">YouTube</a></span>`)
                }
            } else if (overlayTemplate==="overlay-epk") {
                $('#overlay-scrim').addClass('epk')
                overlay.doOverlayOpenReveal();
            }
        }

    },

    doOverlayOpenReveal:function() {
        gsap.fromTo($('#overlay' ), 1,
            {
                opacity:0,
            },
            {
                opacity:1,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete:overlay.doOverlayOpenReveal2,
            }
        )
        overlay.resize()
    },
    doOverlayOpenReveal2:function() {
        overlay.overlayIsOpen=true
        overlay.resize()
    },

    doOverlayClose:function() {
        $(".overlay-x .close-button").addClass('active')
        gsap.fromTo($('#overlay' ), 0.5,
            {
                opacity:1,
            },
            {
                opacity:0,
                delay: 0,
                ease: Power4.easeInOut,
                onComplete:overlay.doOverlayClose2
            }
        );

    },
    doOverlayClose2:function() {
        $('#overlay').hide()
        try {
            if (overlay.yt) {
                overlay.yt.stopVideo()
            }
        } catch (e) {}

        //clear object
        //$('#overlay-trailer-api .overlay-video-wrapper').html('<div id="ytplayer"></div>')

        overlay.overlayIsOpen=false
    },

    resize:function(evt) {
        //BGS
        //trace('overlay resize');
        let docWidth = $(window).width();
        let docHeight = $(window).height();

        //TRAILER
        overlay.resizeYTOverlay('#overlay-trailer-api');
    }, //resize

    resizeYTOverlay:function(tid){
        //trace(tid);
        var yt_box_w = $(tid).width();
        var yt_box_h = $(tid).height();
        var padding = 0; //extra padding
        var yt_rat = 640/360;
        var yt_w = yt_box_w-padding;
        var yt_h = yt_w/yt_rat;
        if (yt_h> yt_box_h) {
            yt_h=yt_box_h-padding;
            yt_w=yt_h*yt_rat;
        }

        var caption_h=0;
        if ($(tid+ ' .overlay-video-wrapper').hasClass('hascaption')) {
            caption_h = $(tid+' .overlay-video-caption').height();
            caption_h +=12;
            $(tid+' .overlay-video-caption').css('bottom',-caption_h);
            $(tid+' .overlay-video-caption').css('width',yt_w);
        }

        var yt_t = (yt_box_h-yt_h)/2;
        var yt_l = (yt_box_w-yt_w)/2;
        if (yt_t<0) {
            yt_t=0;
        }
        $(tid+' .overlay-video-wrapper').css({
            top: yt_t,
            left: yt_l,
            width: yt_w,
            height: yt_h
        })
    },

    onPlayerReady(e) {
        overlay.ytOverlayIsReady = true
    },
    onPlayerStateChange(e) {
        //console.log('onPlayerStateChange', e)
        if (e.data === YT.PlayerState.ENDED) {
            overlay.doOverlayClose()
        }
    },
}
export default overlay;
